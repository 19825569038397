import axios from "axios";
const token = localStorage.getItem("accessToken");
export function import_spreadsheet(projectId, entityId, setModalOpen, files, setError, setIsLoading,
                                   handleBuyersFetchClick, buyerFilteredData, setNotification) {
    files.map((file,i)=> {
        setIsLoading(true);
        axios.post(process.env.REACT_APP_API_BASE_URL + '/api/v1/import_spreadsheet/' + projectId + '/' + entityId,
            { "file": file},
            { headers: {
                    'Content-Type': 'multipart/form-data',
                    "Authorization" : `Bearer ${token}`
                }}
        ).then(data => {
            setNotification(`
                Total Rows: ${data.data.total_rows}
                Failed Rows: ${data.data.error_count}
                Errors ${data.data.errors}
            `);
            setModalOpen(false);
            setIsLoading(false);
            handleBuyersFetchClick(projectId, buyerFilteredData);
        }).catch(function (error) {
            console.log(error.config);
        });
    })
}