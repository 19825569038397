import {React, useEffect, useRef, useState} from "react";
import "./entity.css";
import {fetchEntities, fetchEntity} from "../../Functions/EntityFunctions";
import NewEntity from "../../components/Modals/NewEntity";
import {fetchEntityTypes} from "../../Functions/EntityTypes";

export default function EntityManagement() {
    const[action, setAction] = useState('POST');
    const[entityRowData, setEntityRowData] = useState([]);
    const[entity, setEntity] = useState([]);
    const[entityListSorting, setEntityListSorting] = useState({
        sort_one: "",
        sort_one_direction: "asc",
        sort_two: "",
        sort_two_direction:"asc",
        sort_three: "",
        sort_three_direction:"asc",
        sort_four: "",
        sort_four_direction:"asc",
    });
    const[entityTypes, setEntityTypes] = useState([]);
    const[, setError] = useState();
    const[filtered, setFiltered] = useState();
    const[entityFilteredData, setEntityFilteredData] = useState({code: "", name: "", active: "", entity_type_id:""});
    const[filterOpen, setFilterOpen] = useState(false);
    const[modalIsOpen, setIsOpen] = useState(false);
    const[sortOpen, setSortOpen] = useState();
    const[sorted, setSorted] = useState(false);
    const[updateEntityList, setUpdateEntityList] = useState();
    const sort_array = [
        {id: "id", name: "ID"},
        {id: "code", name: "Code"},
        {id: "entity_name", name: "Name"},
        {id: "active", name: "Active"},
    ];
    const filterRef = useRef(null);
    const sortRef = useRef(null);

    const handleClickOutside = event => {
        if (filterRef.current && !filterRef.current.contains(event.target)) {
            // Close the modal here
            setFilterOpen(false);
        }
        if (sortRef.current && !sortRef.current.contains(event.target)) {
            // Close the modal here
            setSortOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleEntityFetch = (e) => {
        for (const key in entityFilteredData) {
            if (entityFilteredData[key] !== "") {
                setFiltered(true);
                break;
            }
        }

        for (const key in entityListSorting) {
            if (entityListSorting[key] !== "" && entityListSorting[key] !== "asc") {
                setSorted(true);
                break;
            }
        }

        fetchEntities(setEntityRowData, entityFilteredData, setFilterOpen, entityListSorting, setSortOpen);
        setUpdateEntityList(false);
    };

    useEffect(() => { handleEntityFetch(); }, [updateEntityList]);
    useEffect( () => { fetchEntityTypes(setEntityTypes); }, []);

    function clearFilter() {
        const emptyFilterData = Object.keys(entityFilteredData).reduce((acc, key) => {
            acc[key] = "";
            return acc;
        }, {});
        setEntityFilteredData(emptyFilterData);
        setFiltered(false);
        setUpdateEntityList(true);
    }

    function clearSort() {
        const emptySortingData = Object.keys(entityListSorting).reduce((acc, key) => {
            if (key.endsWith('_direction')) {
                acc[key] = 'asc';
            } else {
                acc[key] = '';
            }
            return acc;
        }, {});
        setEntityListSorting(emptySortingData);
        setSorted(false);
        setUpdateEntityList(true);
    }

    function fetchEntityData(id) {
        setError(false);
        setAction('PUT');
        setIsOpen(true);
        fetchEntity(id, setEntity, setError);
    }

    function saveEntityData() {
        setEntity([]);
        setIsOpen(true);
        setAction("POST");
    }

    return <>
        <h2>Entity Management</h2>
        <NewEntity
            modalIsOpen={modalIsOpen}
            setIsOpen={setIsOpen}
            action={action}
            entity={entity}
            setUpdateEntityList={setUpdateEntityList}
        />

        <div> {/* Filter Code */}
            <button className='grayButton' onClick={() => setFilterOpen(!filterOpen)}>Filter</button>
            {filtered && <button className='grayButton' onClick={()=> clearFilter()}>Clear Filter</button>}
            {!filtered && <button className='lightGrayButton' onClick={()=> clearFilter()}>Clear Filter</button>}

            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <button className='grayButton' onClick={() => setSortOpen(!sortOpen)}>Sort</button>
            {sorted && <button className='grayButton' onClick={()=> clearSort()}>Clear Sort</button>}
            {!sorted && <button className='lightGrayButton' onClick={()=> clearSort()}>Clear Sort</button>}

            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <button className="blueButton" onClick={() => {saveEntityData()}}>Create New Entity</button>
            {filterOpen ? (
                <ul className="menu thirtyFourPercentMenu" ref={filterRef}>
                    <li className="menu-item">
                        Code
                        <input type="text" defaultValue={entityFilteredData.code} placeholder="Code" onChange={(e) =>
                            setEntityFilteredData( {...entityFilteredData, code: e.target.value})}>
                        </input>
                    </li>

                    <li className="menu-item">
                        Name
                        <input type="text" defaultValue={entityFilteredData.entity_name} placeholder="Name" onChange={(e) =>
                            setEntityFilteredData({...entityFilteredData, entity_name:  e.target.value})}>
                        </input>
                    </li>

                    <li className="menu-item">
                        Type <br/>
                        <select defaultValue={entityFilteredData.entity_type_id} onChange={(e) =>
                                setEntityFilteredData( {...entityFilteredData, entity_type_id:  e.target.value})}>
                            <option>Entity Type</option>
                            {entityTypes && entityTypes.map((entityType,i)=> {
                                return (
                                    <option value={entityType.id}>{entityType.entity_type}</option>
                                )
                            })}
                        </select>
                    </li>

                    <li className="menu-item">
                        Active <br/>
                        <select defaultValue={entityFilteredData.active} onChange={(e) =>
                            setEntityFilteredData( {...entityFilteredData, active:  e.target.value})} >
                            <option>Select Active/Inactive</option>
                            <option value={true}>Active</option>
                            <option value={false}>Inactive</option>
                        </select>
                    </li>

                    <li>
                        <button onClick={() => handleEntityFetch()}>Apply</button>
                    </li>
                </ul>
            ) : null}
            {sortOpen ? (
                <ul className="menu fortySixPercentMenu"  ref={sortRef}>
                    <li className="menu-item">
                        Sort By
                        <select name="id" defaultValue={entityListSorting.sort_one}
                                onChange={(e) =>
                                    setEntityListSorting({...entityListSorting, sort_one: e.target.value})}>
                            <option value={0}>Sort Column</option>
                            {sort_array.map((sort,i)=> {
                                return (
                                    <option key={sort.id} value={sort.id}>{sort.name}</option>
                                )
                            })}
                        </select>

                        <select name="sort_one_direction" defaultValue={entityListSorting.sort_one_direction}
                                onChange={(e) =>
                                    setEntityListSorting({...entityListSorting, sort_one_direction: e.target.value})}>
                            <option value="asc">Ascending</option>
                            <option value="desc">Descending</option>
                        </select>
                    </li>

                    <li className="menu-item">
                        Then By
                        <select name="sort_two" defaultValue={entityListSorting.sort_two}
                                onChange={(e) =>
                                    setEntityListSorting({...entityListSorting, sort_two: e.target.value})}>
                            <option value={0}>Sort Column</option>
                            {sort_array.map((sort,i)=> {
                                return (
                                    <option key={sort.id} value={sort.id}>{sort.name}</option>
                                )
                            })}
                        </select>
                        <select name="sort_two_direction" defaultValue={entityListSorting.sort_two_direction}
                                onChange={(e) =>
                                    setEntityListSorting({...entityListSorting, sort_two_direction: e.target.value})}>
                            <option value="asc">Ascending</option>
                            <option value="desc">Descending</option>
                        </select>
                    </li>
                    <li className="menu-item">
                        Then By
                        <select name="sort_three" defaultValue={entityListSorting.sort_three}
                                onChange={(e) =>
                                    setEntityListSorting({...entityListSorting, sort_three: e.target.value})}>
                            <option value={0}>Sort Column</option>
                            {sort_array.map((sort,i)=> {
                                return (
                                    <option key={sort.id} value={sort.id}>{sort.name}</option>
                                )
                            })}
                        </select>
                        <select name="sort_three_direction" defaultValue={entityListSorting.sort_three_direction}
                                onChange={(e) =>
                                    setEntityListSorting({...entityListSorting, sort_three_direction: e.target.value})}>
                            <option value="asc">Ascending</option>
                            <option value="desc">Descending</option>
                        </select>
                    </li>

                    <li className="menu-item">
                        Then By
                        <select name="sort_four" defaultValue={entityListSorting.sort_four}
                                onChange={(e) =>
                                    setEntityListSorting({...entityListSorting, sort_four: e.target.value})}>
                            <option value={0}>Sort Column</option>
                            {sort_array.map((sort,i)=> {
                                return (
                                    <option key={sort.id} value={sort.id}>{sort.name}</option>
                                )
                            })}
                        </select>
                        <select name="sort_four_direction" defaultValue={entityListSorting.sort_four_direction}
                                onChange={(e) =>
                                    setEntityListSorting({...entityListSorting, sort_four_direction: e.target.value})}>
                            <option value="asc">Ascending</option>
                            <option value="desc">Descending</option>
                        </select>
                    </li>

                    <li>
                        <button onClick={() => handleEntityFetch()}>Apply</button>
                    </li>
                </ul>
            ) : null}
        </div>

        <table className="entityListTable">
            <thead>
            <tr className="outline">
                <td className="outline">Code</td>
                <td className="outline">Name</td>
                <td className="outline">Entity Type</td>
                <td className="outline">Active</td>
                <td></td>
            </tr>
            </thead>
            <tbody>
            {entityRowData && entityRowData.map((entityData,i)=>{
                const entityId = entityData.id;
                return (
                    <tr key={entityData.id} className="outline">
                        <td className="outline">{entityData.code}</td>
                        <td className="outline">{entityData.entity_name}</td>
                        <td className="outline">{entityData.entity_type_name}</td>
                        <td className="outline">{entityData.active===true ? "Active" : "Inactive"}</td>
                        <td><button className="grayButton" onClick={() => {fetchEntityData(entityId)}}>
                            Edit
                        </button></td>
                    </tr>
                )
            })}
            </tbody>
        </table>
    </>

}