import {React, useEffect, useState, useRef} from "react";
import Modal from "react-modal";
import {useForm} from "react-hook-form";
import './buyerList.css';
import Dropzone from "../../components/FileUploader/Dropzone";

import {
    BUYER_PREFERRED_FILE_TYPE,
    customStyles,
    DATE_RANGE,
    NEW_RESPONSE_FORM, REFRESH_RATE,
    RESPOND_BUTTON_TEXT,
    statusSelect
} from "../../Constants";
import {deleteBuyersList, fetchBuyersList, fetchBuyerRequest} from "../../Functions/BuyersList";
import { getResponseData, saveNewResponse, updateResponse } from "../../Functions/Responses";
import {fetchSellerRequest} from "../../Functions/SellersList";
import DeleteConfirmation from "../../components/Modals/DeleteConfirmation";
import NewBuyerRequest from "../../components/Modals/NewBuyerRequest";
import {ReopenModal} from "./ReopenModal";
import useLocalStorageInt from "../../Functions/useLocalStorageInt";
import {calculate_date_time} from "../../Functions/DateTimeFunctions";
import {fetchStatuses} from "../../Functions/StatusFunction";
import {downloadFile} from "../../Functions/Files";


export default function BuyersList() {
    const { register, handleSubmit , reset,setValue} = useForm();
    const { register:registerResponse, handleSubmit:handleSubmitResponse , reset:resetResponse} = useForm();
    const { register: registerUpdate, handleSubmit: handleSubmitUpdate , reset:resetUpdate} = useForm();
    const[action, setAction] = useState();
    const[buyersListRowData, setBuyersListRowData] = useState([]);
    const[buyerRequest, setBuyerRequest] = useState([]);
    const[deleteConfirmationModalOpen, setdeleteConfirmationModalOpen] = useState();
    const[documentsUploading, setDocumentsUploading] = useLocalStorageInt("documentsUploading", localStorage.getItem("documentsUploading"));
    const[editResponseData, ] = useState([]);
    const[error, setError] = useState();
    const[filterOpen, setFilterOpen] = useState(false);
    const[files, setFiles] = useState([]);
    const[isLoading, setIsLoading] = useState(false);
    const[modalIsOpen, setIsOpen] = useState(false);
    const[projectId, setProjectId] = useState(localStorage.getItem('projectId'));
    const[requestAction, setRequestAction] = useState();
    const[responseError, setResponseError] = useState();
    const[ResponseViewModalOpen, setOpenViewResponseModal] = useState(false);;
    const[ResponseModalOpen, setOpenResponseModal] = useState(false);
    const[reopenModal, setReopenModal] = useState(false);
    const[reopenModalSellerRequestId, setReopenModalSellerRequestId] = useState(false);
    const[sellerRequest, setSellerRequest] = useState([]);
    const[sellerResponseRequestId, setSellerResponseRequestId] = useState('');
    const[sellerResponseViewData, setSellerResponseViewData] = useState([]);
    const[sellerResponseId, setSellerResponseId] = useState();
    const[sortOpen, setSortOpen] = useState();
    const[sorted, setSorted] = useState(false);
    const[statuses, setStatuses] = useState([]);
    const[updateListData, setUpdateListData] = useState(false);
    const[filtered, setFiltered] = useState(false);
    const[popup, setPopup] = useState({
        show: false, // initial values set to false and null
        id: null,
    });
    const[buyerFilteredData, setBuyerFilteredData] = useState({
        buyer_request_list_id: "",
        category: "",
        date_range: "",
        details: "",
        entity_id: "",
        last_response: "",
        linkedRequests: "",
        phase: "",
        priority: "",
        requestId: "",
        request_name: "",
        seller_request_id: "",
        seller_request_status: "",
        status_id: "",
    });
    const[buyerListSorting, setBuyerListSorting] = useState({
        sort_one: "",
        sort_one_direction: "asc",
        sort_two: "",
        sort_two_direction:"asc",
        sort_three: "",
        sort_three_direction:"asc",
        sort_four: "",
        sort_four_direction:"asc",
        sort_five: "",
        sort_five_direction:"asc",
        sort_six: "",
        sort_six_direction:"asc",
        sort_seven: "",
        sort_seven_direction:"asc",
        sort_eight: "",
        sort_eight_direction:"asc",
        sort_nine: "",
        sort_nine_direction:"asc",
        sort_ten: "",
        sort_ten_direction:"asc",
        sort_eleven: "",
        sort_eleven_direction:"asc",
        sort_twelve: "",
        sort_twelve_direction:"asc",
    });
    const sort_array = [
        {id: "buyer_request_list_id", name: "Buyer Req ID"}, //Buyer Req ID
        {id: "priority", name:"Priority"}, //Priority
        {id: "category", name:"Category"}, //Category
        {id: "request_name", name:"Request Name"}, //Request Name
        {id: "buyer_request_list.details", name:"Request Details"}, //Request Details
        // {id: "date_range", name:DATE_RANGE},
        // {id: "file_type.file_type", name:BUYER_PREFERRED_FILE_TYPE},
        {id: "seller_request_list.seller_request_id", name:"Seller Req ID"}, //Seller Req ID
        // {id: "seller_request_list.batch", name:"Phase"},
        // {id: "seller_request_list.assigned_date", name:"Released"},
        {id: "status.status", name:"Seller Request Status"}, //Seller Request Status
        {id: "seller_request_response.time_created", name:"Seller Last Response"}, //Seller Last Response
    ];
    const filterRef = useRef(null);
    const sortRef = useRef(null);

    const handleClickOutside = event => {
        if (filterRef.current && !filterRef.current.contains(event.target)) {
            // Close the modal here
            setFilterOpen(false);
        }
        if (sortRef.current && !sortRef.current.contains(event.target)) {
            // Close the modal here
            setSortOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleBuyersFetchClick = (e) => {
        setError(null);
        for (const key in buyerFilteredData) {
            if (buyerFilteredData[key] !== "") {
                setFiltered(true);
                break;
            }
        }

        for (const key in buyerListSorting) {
            if (buyerListSorting[key] !== "" && buyerListSorting[key] !== 'asc') {
                setSorted(true);
                break;
            }
        }
        fetchBuyersList(projectId, buyerFilteredData, setBuyersListRowData, setFilterOpen, buyerListSorting, setSortOpen);
        setUpdateListData(false);
    };

    useEffect(() => {
        const intervalId = setInterval(() => {
            setUpdateListData(true);
        }, REFRESH_RATE);
        // Clear interval on component unmount
        return () => clearInterval(intervalId); }
    , []);

    useEffect(handleBuyersFetchClick, [updateListData]);

    useEffect(() => fetchStatuses(setStatuses), []);
    function clearFilter() {
        const emptyFilterData = Object.keys(buyerFilteredData).reduce((acc, key) => {
            acc[key] = "";
            return acc;
        }, {});
        setBuyerFilteredData(emptyFilterData);
        handleBuyersFetchClick();
        setFiltered(false);
        setUpdateListData(true);
    }

    function clearSort() {
        const emptySortingData = Object.keys(buyerListSorting).reduce((acc, key) => {
            if (key.endsWith('_direction')) {
                acc[key] = 'asc';
            } else {
                acc[key] = '';
            }
            return acc;
        }, {});
        setBuyerListSorting(emptySortingData);
        handleBuyersFetchClick();
        setUpdateListData(true);
        setSorted(false);
    }

    function createNewBuyerRequest() {
        setBuyerRequest([]);
        reset();
        resetResponse();
        setRequestAction("POST");
        setIsOpen(true);
    }

    function editBuyerRequest(buyerRequestId, action) {
        setRequestAction(action);
        setIsOpen(true);
        fetchBuyerRequest(buyerRequestId, setBuyerRequest);
    }

    const openFilter = () => {
        setFilterOpen(!filterOpen);
    };

    const openSort = () => { setSortOpen(!sortOpen); };

    function openResponseModal(seller_request_id) {
        reset();
        setAction("POST");
        fetchSellerRequest(seller_request_id, setSellerRequest);
        getResponseData(seller_request_id, setSellerResponseViewData);
        setOpenViewResponseModal(false);
        setSellerResponseRequestId(seller_request_id);
        setOpenResponseModal(!ResponseModalOpen);
    }

    function reopen(sellerRequestId) {
        setReopenModal(true);
        setReopenModalSellerRequestId(sellerRequestId);
    }

    function saveResponse(data) {
        saveNewResponse(data, setDocumentsUploading, parseInt(documentsUploading), setResponseError, files, setFiles,
            setIsLoading, setError, setOpenResponseModal, reset, setUpdateListData);
        handleBuyersFetchClick();
        setUpdateListData(true);
    }

    const handleDelete = (id) => {
        setdeleteConfirmationModalOpen(true)
        setPopup({
            show: true,
            id,
        });
    };

    const handleDeleteTrue = () => {
        if (popup.show && popup.id) {
            deleteBuyersList(popup.id, projectId, buyerFilteredData, setBuyersListRowData, setFilterOpen)
            setPopup({
                show: false,
                id: null,
            });
        }
    };

    const handleDeleteFalse = () => {
        setPopup({
            show: false,
            id: null,
        });
    };

    const handleCloseResponseModal = () => {
        setOpenResponseModal(false);
        setResponseError();
        setFiles([]);
        setIsLoading(false);
    }
    return (
        <div>
            <ReopenModal
                open={reopenModal}
                setOpen={setReopenModal}
                sellerRequestid={reopenModalSellerRequestId}
            />

            <Modal
                isOpen={ResponseModalOpen}
                onRequestClose={() => handleCloseResponseModal}
                style={customStyles}
                contentLabel="{NEW_RESPONSE_FORM}"
                ariaHideApp={false}
            >
                <h2>{NEW_RESPONSE_FORM}</h2>
                <button className="grayButton" onClick={() => handleCloseResponseModal()}>close</button>
                {isLoading && <h1 className="redButton">Loading....</h1>}
                {responseError && <h1 className="redButton">{responseError}</h1>}
                {sellerRequest &&
                    <div>
                        <table className='sellerListTable'>
                            <thead>
                            <tr className="outline">
                                <th className="outline">Phase</th>
                                <th className="outline">Requested On</th>
                                <th className="outline">Seller Req ID</th>
                                <th className="outline">Category</th>
                                <th className="outline">Request Name</th>
                                <th className="outline">Request Details</th>
                                <th className="outline">{DATE_RANGE}</th>
                                <th className="outline">{BUYER_PREFERRED_FILE_TYPE}</th>
                                <th className="outline">Status</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr key={sellerRequest.id} className="outline">
                                <td className="outline">{sellerRequest.batch}</td>
                                <td className="outline">{sellerRequest.assigned_date}</td>
                                <td className="outline">{sellerRequest.seller_request_id}</td>
                                <td className="outline">{sellerRequest.category}</td>
                                <td className="outline">{sellerRequest.data_requested}</td>
                                <td className="outline">{sellerRequest.details}</td>
                                <td className="outline">{sellerRequest.parameters}</td>
                                <td className="outline">{sellerRequest.file_format}</td>
                                <td className="outline">{sellerRequest.status}</td>
                            </tr>
                            </tbody>
                        </table>
                        <br />
                        <br />
                        <br />
                        {sellerResponseViewData.length > 0 && <>
                        <h1>Responses</h1>
                            <table className='sellerListTable'>
                                <thead>
                                <tr>
                                    <th className="outline">Response ID</th>
                                    <th className="outline">Uploaded Date</th>
                                    <th className="outline">Uploaded By</th>
                                    <th className="outline">Comments</th>
                                    <th className="outline">Last User Download</th>
                                    <th className="outline">Last Company Download</th>
                                    <th className="outline">Document</th>
                                </tr>
                                </thead>
                                <tbody>

                            {sellerResponseViewData && sellerResponseViewData.map((sellerResponses,i)=>{
                                const documents = sellerResponses.docstorage;
                                let last_user_download = '';
                                let last_entity_download = '';
                                let time_created = '';
                                if (sellerResponses.last_user_download !== '') { last_user_download = calculate_date_time(sellerResponses.last_user_download); }
                                if (sellerResponses.last_entity_download !== '') { last_entity_download = calculate_date_time(sellerResponses.last_entity_download); }
                                if (sellerResponses.time_created !== '') { time_created = calculate_date_time(sellerResponses.time_created); }

                                return (
                                    <>
                                        <tr key={sellerResponses.id}>
                                            <td className="outline">{sellerResponses.seller_response_id}</td>
                                            <td className="outline">{time_created}</td>
                                            <td className="outline">{sellerResponses.user}</td>
                                            <td className="outline">{sellerResponses.response_details}</td>
                                            <td className="outline">{last_user_download}</td>
                                            <td className="outline">{last_entity_download}</td>
                                            <td className="outline">
                                                {documents && documents.map((document, )=>{
                                                    const rand = Boolean(Math.round(Math.random()));
                                                    const formStyle = {
                                                        display: "contents"
                                                    }
                                                    return (<>
                                                        <form onSubmit={handleSubmit(data => downloadFile(document.blob_name_raw, document.blob_name))}
                                                              style={formStyle}>
                                                            <input type="submit" value={document.blob_name} className="grayButton"/>
                                                        </form>
                                                    </>)
                                                })
                                                }
                                            </td>

                                        </tr>
                                    </>
                                )})}
                            </tbody>
                        </table>
                        </>}
                    </div>
                }
                <br />
                <br />
                <br />
                {action === "POST" &&
                    <form onSubmit={handleSubmit(data => saveResponse(data))}>
                        <input name="response_type" defaultValue="buyer" {...register('response_type')} hidden />
                        <input name="approved" defaultValue={false} {...register('approved')} hidden />
                        <input name="seller_request_id" defaultValue={sellerResponseRequestId} {...register('seller_request_id')} hidden />
                        <input name="response_id" defaultValue={sellerResponseId} {...register('response_id')} hidden />

                        <label>Details <br /><em>Any pertinent information that might be needed</em></label>
                        <input name="response_details" {...register('response_details')} />

                        <input type="submit" value="Save Response" className="saveNewSellerRequestButton"/>

                        <Dropzone myFiles={files} setMyFiles={setFiles} width="300px" height='400px'/>
                    </form>
                }

                {action === "PUT" &&
                    <form onSubmit={handleSubmitUpdate(data => updateResponse(data,
                        setResponseError, files, setIsLoading, setOpenResponseModal, fetchBuyersList,
                        setUpdateListData, setError))}>
                        <input name="id" defaultValue={editResponseData.id} {...registerUpdate('id')} hidden />
                        <label>Request Status</label>
                        <select name="status" {...registerUpdate('status')}>
                            {statusSelect.map((status,i) => {
                                return (
                                    <option value={status.name}>{status.name}</option>
                                )
                            })}
                        </select>

                        <label>Details <br /><em>Any pertinent information that might be needed</em></label>
                        <input name="response_details" defaultValue={editResponseData.response_details}  {...registerUpdate('response_details')} />

                        <input type="submit" value="Update Response" className="saveNewSellerRequestButton"/>

                        <Dropzone myFiles={files} setMyFiles={setFiles} width="300px" height='400px'/>
                    </form>
                }
            </Modal>

            {projectId && <h2><div style={{color:"#3178C6"}}>Project {localStorage.getItem('projectname')}</div><div style={{color:"gray"}}>Buyer Request List</div></h2>}
            {!projectId && <div>
                <h2><div style={{color:"red"}}>Project Not Selected</div>
                    <div style={{color:"gray"}}>Buyer Request List</div>
                </h2>
                <em style={{color:"red"}}>Please select a project on the Home page</em>
            </div>}
            {error && (
                <h1 className="userError" dangerouslySetInnerHTML={{ __html: error }} />
            )}
            <br />
            <NewBuyerRequest
                setIsOpen={setIsOpen}
                requestAction={requestAction}
                buyerRequest={buyerRequest}
                setBuyerRequest={setBuyerRequest}
                projectId={projectId}
                buyerFilteredData={buyerFilteredData}
                setBuyersListRowData={setBuyersListRowData}
                setFilterOpen={setFilterOpen}
                modalIsOpen={modalIsOpen}
            ></NewBuyerRequest>

            {popup.show && (
                <DeleteConfirmation
                    handleDeleteTrue={handleDeleteTrue}
                    handleDeleteFalse={handleDeleteFalse}
                    deleteConfirmationModalOpen={deleteConfirmationModalOpen}
                    setdeleteConfirmationModalOpen={setdeleteConfirmationModalOpen}
                />
            )}

            <div>
                {projectId && <>
                    <button className="grayButton" onClick={openFilter}>Filter</button>
                    {filtered && <button className="grayButton" onClick={()=>clearFilter()}>Clear Filter</button>}
                    {!filtered && <button className="lightGrayButton" onClick={()=>clearFilter()}>Clear Filter</button>}

                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <button className="grayButton" onClick={openSort}>Sort</button>
                    {sorted && <button className="grayButton" onClick={()=>clearSort()}>Clear Sort</button>}
                    {!sorted && <button className="lightGrayButton" onClick={()=>clearSort()}>Clear Sort</button>}
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                    <button className="blueButton" onClick={() => createNewBuyerRequest()} >
                        New Buyer Request
                    </button>
                </>}

                {filterOpen ? (
                    <ul className="menu thirtyFourPercentMenu" ref={filterRef}>
                        <li className="menu-item">
                            Linked <br />
                            <select name="filterlinkedRequests" defaultValue={buyerFilteredData.linkedRequests} onChange={(e) =>
                                setBuyerFilteredData( {...buyerFilteredData, linkedRequests: e.target.value})}>
                                <option value="all">All</option>
                                <option value="unlinked">Unlinked</option>
                                <option value="linked">Linked</option>
                            </select>
                        </li>
                        <li className="menu-item">
                            Buyer Req ID
                            <input type="text" defaultValue={buyerFilteredData.buyer_request_list_id} name="buyer_request_list_id" placeholder="Buyer Req ID"
                                   onChange={(e) =>
                                       setBuyerFilteredData( {...buyerFilteredData, buyer_request_list_id: e.target.value})}>
                            </input>
                        </li>
                        <li className="menu-item">
                            Priority <br />
                            <select  name="filterPriority" defaultValue={buyerFilteredData.priority}
                                     onChange={(e) =>
                                         setBuyerFilteredData( {...buyerFilteredData, priority: e.target.value})}>
                                <option value="">Filter By Priority</option>
                                <option value="Low">Low</option>
                                <option value="Medium">Medium</option>
                                <option value="High">High</option>
                            </select>
                        </li>
                        <li className="menu-item">
                            Category
                            <input type="text" name="filterCategory" defaultValue={buyerFilteredData.category} placeholder="Category"
                                   onChange={(e) =>
                                       setBuyerFilteredData( {...buyerFilteredData, category: e.target.value})}>
                            </input>
                        </li>
                        <li className="menu-item">
                            Request Name
                            <input type="text" name="filterRequestName" defaultValue={buyerFilteredData.request_name} placeholder="Request Name"
                                   onChange={(e) =>
                                       setBuyerFilteredData( {...buyerFilteredData, request_name: e.target.value})}>
                            </input>
                        </li>
                        <li className="menu-item">
                            Request Details
                            <input type="text" defaultValue={buyerFilteredData.details} name="filterRequestId" placeholder="Details"
                                   onChange={(e) =>
                                       setBuyerFilteredData( {...buyerFilteredData, details: e.target.value})}>
                            </input>
                        </li>
                        <li className="menu-item">
                            Seller Req ID
                            <input type="text" defaultValue={buyerFilteredData.seller_request_id} name="filterRequestId" placeholder="Seller Req ID"
                                   onChange={(e) =>
                                       setBuyerFilteredData( {...buyerFilteredData, seller_request_id: e.target.value})}>
                            </input>
                        </li>

                        <li className="menu-item">
                            Seller Request Status <br />
                            <select name="status_id" onChange={(e) =>
                                setBuyerFilteredData( {...buyerFilteredData, status_id: e.target.value})}>
                                <option>Select Seller Request Status</option>
                                {statuses.map((status,i) => {
                                    return (
                                        <option value={status.id}>{status.status}</option>
                                    )
                                })}
                            </select>
                        </li>

                        <li>
                            <button className="grayButton" onClick={() => handleBuyersFetchClick()}>Apply</button>
                        </li>
                    </ul>
                ) : null}
                {sortOpen ? (
                    <ul className="menu fortySixPercentMenu" ref={sortRef}>
                        <li className="menu-item">
                            Sort By
                            <select name="id" defaultValue={buyerListSorting.sort_one}
                                    onChange={(e) =>
                                        setBuyerListSorting({...buyerListSorting, sort_one: e.target.value})}>
                                <option value={0}>Sort Column</option>
                                {sort_array.map((sort,i)=> {
                                    return (
                                        <option key={sort.id} value={sort.id}>{sort.name}</option>
                                    )
                                })}
                            </select>

                            <select name="sort_one_direction" defaultValue={buyerListSorting.sort_one_direction}
                                    onChange={(e) =>
                                        setBuyerListSorting({...buyerListSorting, sort_one_direction: e.target.value})}>
                                <option value="asc">Ascending</option>
                                <option value="desc">Descending</option>
                            </select>
                        </li>

                        <li className="menu-item">
                            Then By
                            <select name="sort_two" defaultValue={buyerListSorting.sort_two}
                                    onChange={(e) =>
                                        setBuyerListSorting({...buyerListSorting, sort_two: e.target.value})}>
                                <option value={0}>Sort Column</option>
                                {sort_array.map((sort,i)=> {
                                    return (
                                        <option key={sort.id} value={sort.id}>{sort.name}</option>
                                    )
                                })}
                            </select>
                            <select name="sort_two_direction" defaultValue={buyerListSorting.sort_two_direction}
                                    onChange={(e) =>
                                        setBuyerListSorting({...buyerListSorting, sort_two_direction: e.target.value})}>
                                <option value="asc">Ascending</option>
                                <option value="desc">Descending</option>
                            </select>
                        </li>
                        <li className="menu-item">
                            Then By
                            <select name="sort_three" defaultValue={buyerListSorting.sort_three}
                                    onChange={(e) =>
                                        setBuyerListSorting({...buyerListSorting, sort_three: e.target.value})}>
                                <option value={0}>Sort Column</option>
                                {sort_array.map((sort,i)=> {
                                    return (
                                        <option key={sort.id} value={sort.id}>{sort.name}</option>
                                    )
                                })}
                            </select>
                            <select name="sort_three_direction" defaultValue={buyerListSorting.sort_three_direction}
                                    onChange={(e) =>
                                        setBuyerListSorting({...buyerListSorting, sort_three_direction: e.target.value})}>
                                <option value="asc">Ascending</option>
                                <option value="desc">Descending</option>
                            </select>
                        </li>

                        <li className="menu-item">
                            Then By
                            <select name="sort_four" defaultValue={buyerListSorting.sort_four}
                                    onChange={(e) =>
                                        setBuyerListSorting({...buyerListSorting, sort_four: e.target.value})}>
                                <option value={0}>Sort Column</option>
                                {sort_array.map((sort,i)=> {
                                    return (
                                        <option key={sort.id} value={sort.id}>{sort.name}</option>
                                    )
                                })}
                            </select>
                            <select name="sort_four_direction" defaultValue={buyerListSorting.sort_four_direction}
                                    onChange={(e) =>
                                        setBuyerListSorting({...buyerListSorting, sort_four_direction: e.target.value})}>
                                <option value="asc">Ascending</option>
                                <option value="desc">Descending</option>
                            </select>
                        </li>

                        <li className="menu-item">
                            Then By
                            <select name="sort_five" defaultValue={buyerListSorting.sort_five}
                                    onChange={(e) =>
                                        setBuyerListSorting({...buyerListSorting, sort_five: e.target.value})}>
                                <option value={0}>Sort Column</option>
                                {sort_array.map((sort,i)=> {
                                    return (
                                        <option key={sort.id} value={sort.id}>{sort.name}</option>
                                    )
                                })}
                            </select>
                            <select name="sort_five_direction" defaultValue={buyerListSorting.sort_five_direction}
                                    onChange={(e) =>
                                        setBuyerListSorting({...buyerListSorting, sort_five_direction: e.target.value})}>
                                <option value="asc">Ascending</option>
                                <option value="desc">Descending</option>
                            </select>
                        </li>

                        <li className="menu-item">
                            Then By
                            <select name="sort_six" defaultValue={buyerListSorting.sort_six}
                                    onChange={(e) =>
                                        setBuyerListSorting({...buyerListSorting, sort_six: e.target.value})}>
                                <option value={0}>Sort Column</option>
                                {sort_array.map((sort,i)=> {
                                    return (
                                        <option key={sort.id} value={sort.id}>{sort.name}</option>
                                    )
                                })}
                            </select>
                            <select name="sort_six_direction" defaultValue={buyerListSorting.sort_six_direction}
                                    onChange={(e) =>
                                        setBuyerListSorting({...buyerListSorting, sort_six_direction: e.target.value})}>
                                <option value="asc">Ascending</option>
                                <option value="desc">Descending</option>
                            </select>
                        </li>
                        <li className="menu-item">
                            Then By
                            <select name="sort_seven" defaultValue={buyerListSorting.sort_seven}
                                    onChange={(e) =>
                                        setBuyerListSorting({...buyerListSorting, sort_seven: e.target.value})}>
                                <option value={0}>Sort Column</option>
                                {sort_array.map((sort,i)=> {
                                    return (
                                        <option key={sort.id} value={sort.id}>{sort.name}</option>
                                    )
                                })}
                            </select>
                            <select name="sort_seven_direction" defaultValue={buyerListSorting.sort_seven_direction}
                                    onChange={(e) =>
                                        setBuyerListSorting({...buyerListSorting, sort_seven_direction: e.target.value})}>
                                <option value="asc">Ascending</option>
                                <option value="desc">Descending</option>
                            </select>
                        </li>
                        <li className="menu-item">
                            Then By
                            <select name="sort_eight" defaultValue={buyerListSorting.sort_eight}
                                    onChange={(e) =>
                                        setBuyerListSorting({...buyerListSorting, sort_eight: e.target.value})}>
                                <option value={0}>Sort Column</option>
                                {sort_array.map((sort,i)=> {
                                    return (
                                        <option key={sort.id} value={sort.id}>{sort.name}</option>
                                    )
                                })}
                            </select>
                            <select name="sort_eight_direction" defaultValue={buyerListSorting.sort_eight_direction}
                                    onChange={(e) =>
                                        setBuyerListSorting({...buyerListSorting, sort_eight_direction: e.target.value})}>
                                <option value="asc">Ascending</option>
                                <option value="desc">Descending</option>
                            </select>
                        </li>

                        <li>
                            <button className="grayButton" onClick={() => handleBuyersFetchClick()}>Apply</button>
                        </li>
                    </ul>
                ) : null}
            </div>

            {projectId && <>
                <table className="sellerListTable">
                    <thead>
                        <tr className="outline">
                            <th className="outline">Buyer Req ID</th>
                            <th className="outline">Priority</th>
                            <th className="outline">Category</th>
                            <th className="outline">Request Name</th>
                            <th className="outline">Request Details</th>
                            <th className="outline">{DATE_RANGE}</th>
                            <th className="outline">{BUYER_PREFERRED_FILE_TYPE}</th>
                            <th className="outline">Seller Req ID</th>
                            <th className="outline">Phase</th>
                            <th className="outline">Released</th>
                            <th className="outline">Seller Request Status</th>
                            <th className="outline">Seller Last Response</th>
                            <th className="whiteHeader" colSpan={2}></th>
                        </tr>
                    </thead>
                    <tbody>
                    {buyersListRowData && (buyersListRowData).map((buyerList,i) => {
                        const showResponse = buyerList.seller_request_id != ""
                            && buyerList.seller_request_status !== 'Closed' && buyerList.assigned_date !== null;
                        const showDelete = buyerList.seller_request_id === "";
                        const showReopen = buyerList.seller_request_status === 'Closed';
                        let last_response_date = '';
                        if (buyerList.last_response !== null) {
                            last_response_date = calculate_date_time(buyerList.last_response.time_created);
                        }

                        let seller_request_status = buyerList.seller_request_status;
                        if (buyerList.assigned_date === null) {
                            seller_request_status = "Future Phase";
                        }
                        return (
                            <tr key={buyerList.id} className="outline">
                                <td className="outline">{buyerList.buyer_request_list_id}</td>
                                <td className="outline">{buyerList.priority}</td>
                                <td className="outline">{buyerList.category}</td>
                                <td className="outline">{buyerList.request_name}</td>
                                <td className="outline">{buyerList.details}</td>
                                <td className="outline">{buyerList.date_range}</td>
                                <td className="outline">{buyerList.file_type}</td>
                                <td className="outline">{buyerList.seller_request_id}</td>
                                <td className="outline">{buyerList.seller_request_phase}</td>
                                <td className="outline">{buyerList.assigned_date}</td>
                                <td className="outline">{seller_request_status}</td>
                                <td className="outline">{last_response_date}</td>

                                <td>
                                    {buyerList.response_count == 1 &&
                                        <button className="grayButton" onClick={() => { openResponseModal(buyerList.request_id) }}>
                                            View {buyerList.response_count} Response
                                        </button>
                                    }
                                    {buyerList.response_count > 1 &&
                                        <button className="grayButton" onClick={() => { openResponseModal(buyerList.request_id) }}>
                                            View {buyerList.response_count} Responses
                                        </button>
                                    }
                                </td>
                                <td>
                                    {showResponse && <button className="blueButton" onClick={() => { openResponseModal(buyerList.request_id) }}>{RESPOND_BUTTON_TEXT}</button>}
                                    {showReopen && <button className="blueButton" onClick={() => { reopen(buyerList.request_id) }}>Request Reopen</button>}
                                    { showDelete
                                        &&
                                        <>
                                            <button className="blueButton" onClick={() => { editBuyerRequest(buyerList.id, "PUT") }}>Edit</button>
                                            <button className="blueButton" onClick={() => { handleDelete(buyerList.id) }}>Delete</button>
                                        </>
                                    }
                                </td>
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
            </>}
        </div>
    )
}